import { useState } from 'react';
import { faInfoCircle, faSquare } from '@fortawesome/pro-solid-svg-icons';
import { Button, Icon } from '@nazka/nazka.mapframe.components';

import { URBAN_ATLAS_COLOR_MAP } from '@App/components/UrbanAtlasGeojsonLayer/urban-atlas-geojson-layer.constants';

import './urban-atlas-cover-legend.styl';

function UrbanAtlasCoverLegend() {
  const [isUACLegendExpanded, setIsUACLegendExpanded] = useState(false);

  return (
    <div className="urban-atlas-cover-legend">
      <Button
        icon={faInfoCircle}
        iconSize="14px"
        color={isUACLegendExpanded ? 'black' : 'grey'}
        layout="ghost"
        hover={false}
        css={{
          padding: 0,
          borderRadius: '50%',
          '&:hover': {
            color: 'black',
          },
        }}
        onClick={() => setIsUACLegendExpanded(prevState => !prevState)}
      />
      {isUACLegendExpanded && (
        <div className="urban-atlas-cover-legend__legend z-depth-2">
          {URBAN_ATLAS_COLOR_MAP.map(color => (
            <div className="urban-atlas-cover-legend__legend__item">
              <Icon
                icon={faSquare}
                color={color.color}
                size="16px"
              />
              <span>{color.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default UrbanAtlasCoverLegend;