import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import getArea from '@turf/area';

import GeneralInformation from '@App/components/BuildingDamages/GeneralInformation/GeneralInformation';
import CostGraph from '@App/components/BuildingDamages/CostGraph/CostGraph';
import CostTable from '@App/components/BuildingDamages/CostTable/CostTable';

import { useUserAreas } from '@utils/queries/user.queries';
import { calculateCostPerClass, calculateTotalDamage } from './building-damages.utils';

import './building-damages.styl';
import CompareModeView from './CompareModeView/CompareModeView';
import DamagesWithUACDataPanel from './DamagesWithUACDataPanel/DamagesWithUACDataPanel';

function BuildingDamages() {
  const isCompareModeOn = useSelector(state => state.buildingDamages.isComparisonModeOn)
  const currentSelectedDamageLayer = useSelector(state => state.layerSelector.currentSelectedDamageLayer)
  const {
    features, totalBuildingCount, areaId, modelId, 
  } = currentSelectedDamageLayer;
  
  const totalDamage = calculateTotalDamage(currentSelectedDamageLayer?.features);
  const costPerClass = useMemo(() => calculateCostPerClass(features), [features]);
  const { data: userAreas } = useUserAreas();
  const selectedArea = userAreas?.find(area => area.id === areaId)
  
  return (
    <div className="building-damages">
      <GeneralInformation
        area={selectedArea ? getArea(selectedArea.feature) : ''}
        totalDamage={totalDamage}
        totalBuildingCount={totalBuildingCount}
        totalBuildingsFloodedCount={features.length}
      />
      {isCompareModeOn ? (<CompareModeView />) : (
        <div>
          {features.length > 0 
            ? (
              <div>
                {modelId === 'uac' ? (
                  <DamagesWithUACDataPanel totalDamage={totalDamage} features={features} costPerClass={costPerClass} />
                ) : (
                  <div>
                    <h3 className="building-damages__header-text">Costs per damage class</h3>
                    <div className="building-damages__table-graph__wrapper">
                      <CostGraph data={costPerClass} totalDamage={totalDamage} width={380} />
                      <CostTable data={costPerClass} />
                    </div>
                  </div>
                )}
             
              </div>
            )
            : (
              <span className="building-damages__no__flood__text">
                No flooded
                {currentSelectedDamageLayer.modelId === 'uac' ? ' features ' : ' buildings '}
                detected
              </span>
            )}
        </div>
      )}
      
    </div>
  );
}

export default BuildingDamages;