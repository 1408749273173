import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { bbox } from '@turf/turf';

function useMunicipalities(country = 'slovenia') {
  return useQuery({
    queryKey: ['map', 'boundaries'],
    queryFn: async () => {
      const { data } = await axios.get(
        `https://boundaries-api.io/api/${country}/geometry/find/level?level=1&detail=2`,
      );

      return {
        type: 'FeatureCollection',
        features: data.geometries.features,
      };
    },
  });
}

function useUrbanAtlasLandUseFeatures(area) {
  return useQuery({
    queryKey: ['map', 'urban-atlas-land-use', area?.id],
    queryFn: async () => {
      const areaBbox = bbox(area.feature);
      const queryStringBbox = `bbox=${areaBbox[0]},${areaBbox[1]},${areaBbox[2]},${areaBbox[3]}`;

      const { data } = await axios.get(`/api/land-use-features/urban-atlas?${queryStringBbox}`);
      return data;
    },
    enabled: !!area,
  });
}

export {
  useMunicipalities,
  useUrbanAtlasLandUseFeatures,
};