import { Checkbox, Tooltip } from '@nazka/nazka.mapframe.components';

import { URBAN_ATLAS_CLASSES_BY_CATEGORY } from '@App/components/UrbanAtlasGeojsonLayer/urban-atlas-geojson-layer.constants';
import CheckboxGroup from '@App/components/CheckboxGroup/CheckboxGroup';

import { getAvailableClasses } from './uac-classes-selector.utils';

import './uac-classes-selector.styl';

function UACClassesSelector({
  availableUACClasses,
  disabled,
  onSelectedUACClassesChange,
  selectedUACClasses,
  isLoading,
}) {
  const availableClasses = getAvailableClasses(URBAN_ATLAS_CLASSES_BY_CATEGORY, availableUACClasses);

  const content = (
    <div className={`uac-classes-selector${isLoading ? ' is-loading' : ''}`} data-tooltip-id="uac-classes-selector-tooltip">
      {Object.keys(availableClasses).map((category) => {
        const classesForCategory = availableClasses[category];

        if (URBAN_ATLAS_CLASSES_BY_CATEGORY[category].length === 1) {
          const [{ value, category: text }] = classesForCategory;

          return (
            <Checkbox
              text={text}
              onInputChange={(event) => {
                if (event.target.checked) {
                  onSelectedUACClassesChange([...selectedUACClasses, value]);
                } else {
                  onSelectedUACClassesChange(selectedUACClasses.filter(v => v !== value));
                }
              }}
              key={value}
              checked={selectedUACClasses.includes(value)}
              disabled={disabled}
            />
          );
        }

        return (
          <CheckboxGroup
            key={category}
            label={category}
            options={classesForCategory}
            selectedOptions={selectedUACClasses.filter(v => classesForCategory.find(c => c.value === v))}
            onSelectedOptionsChange={(selectedClasses) => {
              const newClasses = selectedUACClasses.filter(v => !classesForCategory.find(c => c.value === v));
              newClasses.push(...selectedClasses);
              onSelectedUACClassesChange(newClasses);
            }}
            disabled={disabled}
          />
        );
      })}
    </div>
  );

  if (!disabled) {
    return content;
  }

  return (
    <Tooltip
      id="uac-classes-selector-tooltip"
      tooltip="Please select your area of interest to activate or deactivate layers"
      place="right"
      className="tooltip uac-classes-selector__tooltip"
      noArrow
    >
      {content}
    </Tooltip>
  );
}

export default UACClassesSelector;