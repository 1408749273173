/* eslint-disable max-len */
import { useEffect } from 'react';

const BASE_URL = 'https://image.discomap.eea.europa.eu/arcgis/services/UrbanAtlas/UA_UrbanAtlas_2018/MapServer/WMSServer?service=WMS&request=GetMap&bbox={bbox-epsg-3857}&version=1.3.0&format=image%2Fpng&width=512&height=512&transparent=true&crs=EPSG:3857&styles=';

function UrbanAtlasWMSLayer({ map, opacity }) {
  useEffect(() => {
    if (map && !map.getLayer('urban-atlas-wms')) {
      map.addLayer({
        id: 'urban-atlas-wms',
        type: 'raster',
        source: {
          type: 'raster',
          tiles: [
            `${BASE_URL}&layers=Land_Use_vector52160`,
          ],
          tileSize: 512,
        },
        minzoom: 8,
      }, 'z-index-1');
    }

    map.addLayer({
      id: 'urban-atlas-wms-lower-zoom-levels',
      type: 'raster',
      source: {
        type: 'raster',
        tiles: [
          `${BASE_URL}&layers=Land_Use_Raster1402`,
        ],
        tileSize: 512,
      },
      maxzoom: 9,
    }, 'z-index-1');

    return () => {
      if (map && !map._removed) {
        if (map.getLayer('urban-atlas-wms')) {
          map.removeLayer('urban-atlas-wms');
        }

        if (map.getSource('urban-atlas-wms')) {
          map.removeSource('urban-atlas-wms');
        }

        if (map.getLayer('urban-atlas-wms-lower-zoom-levels')) {
          map.removeLayer('urban-atlas-wms-lower-zoom-levels');
        }

        if (map.getSource('urban-atlas-wms-lower-zoom-levels')) {
          map.removeSource('urban-atlas-wms-lower-zoom-levels');
        }
      }
    }
  }, [map]);
  useEffect(() => {
    if (map) {
      map.setPaintProperty('urban-atlas-wms', 'raster-opacity', opacity);
      map.setPaintProperty('urban-atlas-wms-lower-zoom-levels', 'raster-opacity', opacity);
    }
  }, [map, opacity]);
}

export default UrbanAtlasWMSLayer;