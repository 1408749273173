import { faSquare } from '@fortawesome/pro-solid-svg-icons';

const LEGEND_ITEMS = [
  { key: 0, content: 'Low', iconProps: { icon: faSquare, size: '20px', color: '#ffd700' } },
  { key: 1, content: 'Middle', iconProps: { icon: faSquare, size: '20px', color: '#E5A300' } },
  { key: 2, content: 'High', iconProps: { icon: faSquare, size: '20px', color: '#E86A0A' } },
  { key: 3, content: 'Very high', iconProps: { icon: faSquare, size: '20px', color: '#cf0d14' } },
];

export {
  LEGEND_ITEMS,
}