import { useMemo, useState } from 'react';

import CostGraph from '@App/components/BuildingDamages/CostGraph/CostGraph';
import CostTable from '@App/components/BuildingDamages/CostTable/CostTable';

import './damages-with-uac-datapanel.styl'
import { Button } from '@nazka/nazka.mapframe.components';
import { calculateUACCategory, calculateUACDetail } from '../building-damages.utils';

function DamagesWithUACDataPanel({ totalDamage, costPerClass, features }) {
  const costPerCategory = useMemo(() => calculateUACCategory(features), [features])
  const costPerClassDetail = useMemo(() => calculateUACDetail(features), [features])
  const [damageLevelDisplayed, setDamageLevelDisplayed] = useState('overview')
 
  return (
    <div className="damages-with-uac">
      <h3 className="damages-with-uac__general-info-header">Costs per damage class</h3>
      <div className="damages-with-uac__basic-data">
        <CostGraph data={costPerClass} totalDamage={totalDamage} width={380} />
        <CostTable data={costPerClass} />
      </div>
      
      <div className="damages-with-uac__second-header">
        <h3 className="damages-with-uac">Costs per land use class</h3>
        <div className="damages-with-uac__toggle__wrapper">
          <Button
            onClick={() => setDamageLevelDisplayed('overview')}
            className={`damages-with-uac__toggle__button
              ${damageLevelDisplayed === 'overview' ? 'damages-with-uac__toggle__button--active' : ''}`}
            hover={false}
            css={{
              borderRadius: '5px 0 0 5px',
            }}
          >
            Overview
          </Button>
          <Button
            onClick={() => setDamageLevelDisplayed('detail')}
            className={`damages-with-uac__toggle__button 
              ${damageLevelDisplayed === 'detail' ? 'damages-with-uac__toggle__button--active' : ''}`}
            hover={false}
            css={{
              borderRadius: '0 5px 5px 0',
            }}
          >
            Detail
          </Button>
        </div>
      </div>
      <div className="damage-with-uac__feature-view">
        <div className="damage-with-uac__feature-view__graph__wrapper">
          <CostGraph
            data={damageLevelDisplayed === 'overview' ? costPerCategory : costPerClassDetail}
            totalDamage={totalDamage}
            width={damageLevelDisplayed === 'overview' ? 420 : null}
          />
        </div>
        <div className="damage-with-uac__feature-view__table__wrapper">
          <CostTable data={damageLevelDisplayed === 'overview' ? costPerCategory : costPerClassDetail} />
        </div>
      </div>
      
    </div>
  )
}

export default DamagesWithUACDataPanel