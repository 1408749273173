import { useEffect } from 'react';
import maplibregl from 'maplibre-gl';

import { URBAN_ATLAS_COLOR_MAP } from './urban-atlas-geojson-layer.constants';

const popup = new maplibregl.Popup({
  closeButton: false,
  closeOnClick: false,
});

function UrbanAtlasGeojsonLayer({ map, features, selectedClasses }) {
  useEffect(() => {
    let hoveredFeatureId;

    function setHoverState(id, hover) {
      if (!id) return;

      map.setFeatureState(
        { source: 'urban-atlas-land-use', id },
        { hover },
      );
    }

    function deselectHoveredFeature() {
      popup.remove();

      if (hoveredFeatureId !== null) {
        setHoverState(hoveredFeatureId, false);
        hoveredFeatureId = null;
      }
    }

    function onMouseMove(e) {
      const queriedFeatures = map.queryRenderedFeatures(e.point, {
        layers: ['urban-atlas-land-use'],
      });

      if (queriedFeatures.length > 0) {
        const feature = queriedFeatures[0];

        if (hoveredFeatureId !== feature.id) {
          if (hoveredFeatureId) {
            setHoverState(hoveredFeatureId, false);
          }

          if (feature.id) {
            hoveredFeatureId = feature.id;
            setHoverState(hoveredFeatureId, true);
  
            popup
              .setLngLat(e.lngLat)
              .setHTML(feature.properties.class_2018)
              .addTo(map);
          }
        } else {
          popup.setLngLat(e.lngLat);
        }
      } else {
        deselectHoveredFeature();
      }
    }

    function onMouseOut() {
      deselectHoveredFeature();
    }

    if (map) {
      map.addSource('urban-atlas-land-use', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });

      map.addLayer({
        id: 'urban-atlas-land-use',
        source: 'urban-atlas-land-use',
        type: 'fill',
        paint: {
          'fill-color': [
            'match', ['get', 'code_2018'],
            ...URBAN_ATLAS_COLOR_MAP.flatMap(({ value, color }) => [value.toString(), color]),
            '#000000',
          ],
          'fill-opacity': 0.8,
        },
      }, 'z-index-1');

      map.addLayer({
        id: 'urban-atlas-land-use-line',
        source: 'urban-atlas-land-use',
        type: 'line',
        paint: {
          'line-width': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            0,
          ],
        },
      }, 'z-index-1');

      map.on('mousemove', onMouseMove);
      map.on('mouseout', onMouseOut);
    }

    return () => {
      if (map && !map._removed) {
        map.off('mousemove', onMouseMove);
        map.off('mouseout', onMouseOut);

        popup.remove();

        if (map.getLayer('urban-atlas-land-use')) {
          map.removeLayer('urban-atlas-land-use');
        }

        if (map.getLayer('urban-atlas-land-use-line')) {
          map.removeLayer('urban-atlas-land-use-line');
        }
  
        if (map.getSource('urban-atlas-land-use')) {
          map.removeSource('urban-atlas-land-use');
        }
      }
    };
  }, [map]);

  useEffect(() => {
    if (map && features) {
      map.getSource('urban-atlas-land-use').setData(features);
    }
  }, [features, map]);

  useEffect(() => {
    if (map && selectedClasses && Array.isArray(selectedClasses)) {
      map.setFilter('urban-atlas-land-use', ['in', 'code_2018', ...selectedClasses.map(c => c.toString())]);
    }
  }, [map, selectedClasses]);

  return null;
}

export default UrbanAtlasGeojsonLayer;