import React, {
  useMemo, 
} from 'react';
import { useSelector } from 'react-redux';
import CostGraph from '@App/components/BuildingDamages/CostGraph/CostGraph';

import { calculateCostPerClass, calculateTotalDamage } from '../building-damages.utils';

import './compare-mode-view.styl'
import CompareModeTable from './CompareModeTable/CompareModeTable';

function CompareModeView() {
  const currentSelectedDamageLayer = useSelector(state => state.layerSelector.currentSelectedDamageLayer)
  const secondScenario = useSelector(state => state.buildingDamages.secondScenario)
  const featureOne = currentSelectedDamageLayer.features
  const featureTwo = useMemo(() => (secondScenario ? secondScenario.features : []), [secondScenario])

  const scenarioOnecostPerClass = useMemo(() => calculateCostPerClass(featureOne), [featureOne]);
  const scenarioTwocostPerClass = useMemo(() => calculateCostPerClass(featureTwo), [featureTwo]);

  const totalDamageScenario1 = calculateTotalDamage(currentSelectedDamageLayer?.features)
  const totalDamageScenario2 = calculateTotalDamage(secondScenario?.features);

  return (
    <div className="compare-mode-view">
      <div className="compare-mode-view__graphs">
        <div className="compare-mode-view__graphs__scenario-one">
          <span>Scenario 1</span>
          <CostGraph data={scenarioOnecostPerClass} totalDamage={totalDamageScenario1} width={380} />
        </div>
        <div className="compare-mode-view__graphs__scenario-two">
          <span>Scenario 2</span>
          {secondScenario ? (
            <CostGraph data={scenarioTwocostPerClass} totalDamage={totalDamageScenario2} width={380} />
          ) : (
            <span className="compare-mode-view__noscenario">Select a scenario to load the results</span>
          )}
        </div>
      </div>
      
      <div className="compare-mode-view__tabels">
        <CompareModeTable scenarioOne={scenarioOnecostPerClass} scenarioTwo={scenarioTwocostPerClass} />
      </div>
    </div>
  )
}

export default CompareModeView