/* eslint-disable max-lines */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  faLayerGroup,
  faChevronDown,
  faChevronUp,
  faMap,
  faHouseFloodWater,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import {
  Icon,
  Button,
  Toggle,
  ScrollContainer,
  Radiogroup,
} from '@nazka/nazka.mapframe.components';

import BuildingsLayer from '@App/components/BuildingsLayer/BuildingsLayer';
import CompareModeLayerPanelFloodSection from '@App/components/CompareModeLayerPanel/CompareModeLayerPanelFloodSection';
import FloodDamagesSelector from '@App/components/LayerSelector/FloodDamagesSelector/FloodDamagesSelector';
import FloodScenarioModal from '@App/components/FloodScenarioModal/FloodScenarioModal';
import Slider from '@library/Slider/Slider';
import { useFloodDamages } from '@utils/queries/calculation.queries';
import { useUserSettings, useUpdateUserSettingsMutation, useUserAreas } from '@utils/queries/user.queries';
import { setFloodModalStatus } from '@App/components/LayerSelector/layer-selector.redux.action';
import { setShowData } from '@templates/T1/t1.redux.actions';
import { usePrev } from '@services/hooks.service';

import UrbanAtlasCover from './UrbanAtlasCover/UrbanAtlasCover';

import './layer-selector.styl';

function LayerSelector() {
  const [isOpen, setIsOpen] = useState(true);
  const [isFloodScenarioExpanded, setIsFloodScenarioExpanded] = useState(true)
  const [isLanduseExpanded, setIsLanduseExpanded] = useState(true)
  const [buildingsLayerOpacityLevel, setBuildingsLayerOpacityLevel] = useState(1)

  const { data: floodDamages } = useFloodDamages();
  const { data: userSettings, isLoading: isLoadingUserSettings } = useUserSettings();
  const { data: userAreas } = useUserAreas();

  const {
    floodMeasures,
    floodYear,
    activeLandUseFeature,
    isFloodLayerActive,
  } = userSettings || {};

  const map = useSelector(state => state.maplibreMap.map);
  const isFloodModalOpen = useSelector(state => state.layerSelector.isFloodModalOpen);
  const isCompareModeOn = useSelector(state => state.buildingDamages.isComparisonModeOn)
  const showData = useSelector(state => state.t1.showData);
  const isCreatingNewDamageLayer = useSelector(state => state.newDamageLayer.isCreatingNewDamageLayer);
  const currentSelectedDamageLayer = useSelector(state => state.layerSelector.currentSelectedDamageLayer);

  const prevSelectedCurrentSelectedDamageLayer = usePrev(currentSelectedDamageLayer);

  const dispatch = useDispatch();

  const openModal = () => {
    dispatch(setShowData(false))
    dispatch(setFloodModalStatus(true))
  }

  const { mutate: updateUserSettings } = useUpdateUserSettingsMutation();

  const toggleIsOpen = () => setIsOpen(prevState => !prevState);

  useEffect(() => {
    if (showData) {
      setIsOpen(false)
    }
  }, [showData])

  useEffect(() => {
    if (currentSelectedDamageLayer && !prevSelectedCurrentSelectedDamageLayer) {
      updateUserSettings({
        activeLandUseFeature: null,
      });
    }
  }, [currentSelectedDamageLayer, prevSelectedCurrentSelectedDamageLayer, updateUserSettings]);

  const radios = [
    { label: 'My buildings', value: 'value1' },
  ];
  const [selectedValue, setSelectedValue] = useState(radios[0].value);

  const onRadiogroupUpdate = (e) => {
    setSelectedValue(e.target.value);
  };

  return (
    <div className="layer-selector">
      <div>
        <Button
          icon={isOpen ? faXmark : faLayerGroup}
          onClick={toggleIsOpen}
          color="white"
          hover
          css={{
            borderRadius: '3px',
          }}
        />
      </div>

      <div className={`z-depth-1 layer-selector__container 
        ${isLoadingUserSettings ? ' is-loading' : ''} ${isOpen ? '' : 'layer-selector__container--close'}`}
      >
        <ScrollContainer
          maxHeight={265}
          css={{
            width: '270px',
          }}
        >
          <div className={`layer-selector__body__wrapper ${isOpen ? 'expanded' : ''}`}>
            <hr style={{
              opacity: 0.1, margin: '0 10px',
            }}
            />
            <div className="layer-selector__header__text__layer__wrapper">
              <Icon icon={faHouseFloodWater} size="18px" />
              <span>Flood scenario</span>
              <Button
                icon={isFloodScenarioExpanded ? faChevronUp : faChevronDown}
                size="20px"
                onClick={() => setIsFloodScenarioExpanded(prevState => !prevState)}
                color="white"
                hover={false}
                css={{
                  marginLeft: 'auto',
                }}
              />
            </div>
            {isFloodScenarioExpanded && (
              <div className="layer-selector__flood__scenario">
                <Toggle
                  label={isCompareModeOn ? 'Flood comparison' : `${floodYear}-year flood`}
                  name="flood"
                  checked={isFloodLayerActive}
                  onInputChange={e => updateUserSettings({ isFloodLayerActive: e.target.checked })}
                  activeColor="#0367A5"
                  css={{
                    fontSize: '14px',
                    input: { fontSize: '12px' },
                  }}
                />
                {isCompareModeOn ? (
                  <CompareModeLayerPanelFloodSection />
                ) : (
                  <div>
                    <div className="layer-selector_flood__scenario__info__text">
                      <span>
                        {floodMeasures ? (
                          <span>
                            {floodMeasures === '_Measures' ? 'Anti-flood measures included' : 'Climate change included'}
                          </span>
                        ) : (
                          <span>No scenario</span>
                        )}
                      </span>
                    </div>

                    <Button
                      onClick={openModal}
                      className="layer-selector__change__scenarios"
                      color="#0367A5"
                      hoverColor="#0367A5"
                      layout="outlined"
                      css={{
                        borderRadius: '3px',
                        padding: '3px',
                        fontSize: '12px',
                        marginBottom: '6px',
                      }}
                    >
                      Change flood scenario
                    </Button>
                    {isFloodModalOpen && (
                      <FloodScenarioModal
                        closeModal={() => dispatch(setFloodModalStatus(false))}
                        floodYear={floodYear}
                        floodMeasures={floodMeasures}
                      />
                    )}
                  </div>
                )}

              </div>
            )}

          </div>
          <div>
            <hr style={{
              opacity: 0.1, margin: '0 10px',
            }}
            />
            <div className="layer-selector__header__text__layer__wrapper">
              <Icon icon={faMap} size="16px" />
              <span>Land use features</span>
              <Button
                icon={isLanduseExpanded ? faChevronUp : faChevronDown}
                size="20px"
                onClick={() => setIsLanduseExpanded(prevState => !prevState)}
                color="white"
                hover={false}
                css={{
                  marginLeft: 'auto',
                }}
              />
            </div>
            {isLanduseExpanded && (
              <div>
                <UrbanAtlasCover />
                <Toggle
                  label="Building data"
                  name="building data"
                  checked={activeLandUseFeature === 'Building data'}
                  activeColor="#0367A5"
                  onInputChange={() => updateUserSettings({
                    activeLandUseFeature: `${activeLandUseFeature === 'Building data' ? null : 'Building data'}`,
                  })}
                  css={{
                    fontSize: '14px',
                    input: { fontSize: '12px' },
                    marginBottom: '6px',
                  }}
                />
                <div className="layer-selector__land-use-features__building__options">
                  {activeLandUseFeature === 'Building data' && (
                    <div>
                      <Radiogroup
                        radios={radios}
                        selectedValue={selectedValue}
                        onInputChange={onRadiogroupUpdate}
                        css={{
                          fontSize: '14px',
                        }}
                      />
                      <div className="layer-selector__opacity-slider__wrapper">
                        <span>Opacity:</span>
                        <Slider
                          value={buildingsLayerOpacityLevel}
                          onInputChange={setBuildingsLayerOpacityLevel}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

          </div>
          {userAreas && floodDamages && (
            <>
              <hr style={{ opacity: 0.1, margin: '0 10px' }} />
              <FloodDamagesSelector map={map} />
            </>
          )}
        </ScrollContainer>
      </div>
      {map && activeLandUseFeature === 'Building data' && !isCreatingNewDamageLayer && (
        <BuildingsLayer map={map} opacity={buildingsLayerOpacityLevel} />
      )}
    </div>
  )
}

export default LayerSelector