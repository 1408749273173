const URBAN_ATLAS_COLOR_MAP = [
  {
    value: 11100,
    label: 'Continuous Urban fabric',
    color: '#800000',
    category: 'Artificial Surfaces',
  },
  {
    value: 11210,
    label: 'Discontinuous Dense Urban Fabric',
    color: '#bf0000',
    category: 'Artificial Surfaces',
  },
  {
    value: 11220,
    label: 'Discontinuous Medium Density Urban Fabric',
    color: '#ff4040',
    category: 'Artificial Surfaces',
  },
  {
    value: 11230,
    label: 'Discontinuous Low Density Urban Fabric',
    color: '#ff8080',
    category: 'Artificial Surfaces',
  },
  {
    value: 11240,
    label: 'Discontinuous very low density urban fabric',
    color: '#ffbfbf',
    category: 'Artificial Surfaces',
  },
  {
    value: 11300,
    label: 'Isolated Structures',
    color: '#cc6666',
    category: 'Artificial Surfaces',
  },
  {
    value: 12100,
    label: 'Industrial, commercial, public, military and private units',
    color: '#cc4df2',
    category: 'Artificial Surfaces',
  },
  {
    value: 12210,
    label: 'Fast transit roads and associated land',
    color: '#959595',
    category: 'Artificial Surfaces',
  },
  {
    value: 12220,
    label: 'Other roads and associated land',
    color: '#b3b3b3',
    category: 'Artificial Surfaces',
    disabled: true,
  },
  {
    value: 12230,
    label: 'Railways and associated land',
    color: '#595959',
    category: 'Artificial Surfaces',
  },
  {
    value: 12300,
    label: 'Port areas',
    color: '#e6cccc',
    category: 'Artificial Surfaces',
  },
  {
    value: 12400,
    label: 'Airports',
    color: '#e6cce6',
    category: 'Artificial Surfaces',
  },
  {
    value: 13100,
    label: 'Mineral extraction and dump sites',
    color: '#734d37',
    category: 'Artificial Surfaces',
  },
  {
    value: 13300,
    label: 'Construction sites',
    color: '#b9a56e',
    category: 'Artificial Surfaces',
  },
  {
    value: 13400,
    label: 'Land without current use',
    color: '#874545',
    category: 'Artificial Surfaces',
  },
  {
    value: 14100,
    label: 'Green urban areas',
    color: '#8cdc00',
    category: 'Artificial Surfaces',
  },
  {
    value: 14200,
    label: 'Sports and leisure facilities',
    color: '#afd2a5',
    category: 'Artificial Surfaces',
  },
  {
    value: 21000,
    label: 'Arable land (annual crops)',
    color: '#ffffa8',
    category: 'Agricultural Areas',
  },
  {
    value: 22000,
    label: 'Permanent crops',
    color: '#f2a64d',
    category: 'Agricultural Areas',
  },
  {
    value: 23000,
    label: 'Pastures',
    color: '#e6e64d',
    category: 'Agricultural Areas',
  },
  {
    value: 24000,
    label: 'Complex and mixed cultivation patterns',
    color: '#ffe64d',
    category: 'Agricultural Areas',
  },
  {
    value: 25000,
    label: 'Orchards',
    color: '#f2cc80',
    category: 'Agricultural Areas',
  },
  {
    value: 31000,
    label: 'Forests',
    color: '#008c00',
    category: 'Forests and Semi-natural Areas',
  },
  {
    value: 32000,
    label: 'Herbaceous vegetation associations',
    color: '#ccf24d',
    category: 'Forests and Semi-natural Areas',
  },
  {
    value: 33000,
    label: 'Open spaces with little or no vegetations',
    color: '#ccffcc',
    category: 'Forests and Semi-natural Areas',
  },
  {
    value: 40000,
    label: 'Wetlands',
    color: '#a6a6ff',
    category: 'Wetlands',
  },
  {
    value: 50000,
    label: 'Water',
    color: '#80f2e6',
    category: 'Water Bodies',
  },
];

const URBAN_ATLAS_CLASSES_BY_CATEGORY = URBAN_ATLAS_COLOR_MAP.reduce((acc, item) => {
  if (!item.disabled) {
    acc[item.category] = acc[item.category] || [];
    acc[item.category].push(item);
  }

  return acc;
}, {});

export {
  URBAN_ATLAS_CLASSES_BY_CATEGORY,
  URBAN_ATLAS_COLOR_MAP,
};