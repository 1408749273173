import { URBAN_ATLAS_COLOR_MAP } from '../UrbanAtlasGeojsonLayer/urban-atlas-geojson-layer.constants';

function calculateTotalDamage(features) {
  if (!features) {
    return 0;
  }

  return features.reduce((acc, curr) => acc + curr.properties.totalDamage, 0);
}

function calculateCostPerClass(features) {
  const costPerClass = {
    buildingDamage: {
      value: 0,
      name: 'Building damage',
      color: '#022741',
    },
    equipmentDamage: {
      value: 0,
      name: 'Equipment damage',
      color: '#078BCF',
    },
    vehicleDamage: {
      value: 0,
      name: 'Vehicle damage',
      color: '#099EE1',
    },
    cleaningCosts: {
      value: 0,
      name: 'Cleaning costs',
      color: '#0DC2FF',
    },
    humanHealthDamage: {
      value: 0,
      name: 'Human health damage',
      color: '#0BB0F3',
    },
    farmlandAndForestDamage: {
      value: 0,
      name: 'Farmland and forest damage',
      color: '#0BB0F3',
    },
    environmentDamage: {
      value: 0,
      name: 'Environment damage',
      color: '#0BB0F3',
    },
    infrastructureDamage: {
      value: 0,
      name: 'Infrastructure damage',
      color: '#0BB0F3',
    },
    riversAndStreamDamage: {
      value: 0,
      name: 'Damage to water bodies',
      color: '#0BB0F3',
    },
  };

  for (let i = 0; i < features.length; i += 1) {
    const { properties } = features[i];

    Object.keys(costPerClass).forEach((key) => {
      if (properties[key] && properties[key] !== 0) {
        costPerClass[key].value += properties[key];
      }
    });
  }

  return Object.values(costPerClass).sort((a, b) => b.value - a.value);
}

const calculateUACCategory = (features) => {
  const costPerCategory = {};
  const colorCodes = {
    'Artificial Surfaces': '#022741',
    'Agricultural Areas': '#078BCF',
    'Forests and Semi-natural Areas': '#099EE1',
    Wetlands: '#0DC2FF',
    'Water Bodies': '#0BB0F3',
    others: '#7fd9fd',
  }

  for (let i = 0; i < features.length; i += 1) {
    const { properties } = features[i];
    
    const code = parseInt(properties.code_2018, 10)
    const matchedClass = URBAN_ATLAS_COLOR_MAP.find(item => item.value === code);

    if (matchedClass && !matchedClass.disabled) {
      if (!costPerCategory[matchedClass.category]) {
        costPerCategory[matchedClass.category] = {
          value: 0,
          name: matchedClass.category,
          color: colorCodes[matchedClass.category],
        };
      }

      costPerCategory[matchedClass.category].value += properties.totalDamage || 0;
    }
  }

  return Object.values(costPerCategory).sort((a, b) => b.value - a.value);
}

const calculateUACDetail = (features) => {
  const detailCost = {}
  for (let i = 0; i < features.length; i += 1) {
    const { properties } = features[i];
    
    const code = parseInt(properties.code_2018, 10)
    const matchedClass = URBAN_ATLAS_COLOR_MAP.find(item => item.value === code);

    if (matchedClass && !matchedClass.disabled) {
      if (!detailCost[matchedClass.label]) {
        detailCost[matchedClass.label] = {
          value: 0,
          name: matchedClass.label,
          color: '#022741',
        };
      }

      detailCost[matchedClass.label].value += properties.totalDamage || 0;
    }
  }

  return Object.values(detailCost).sort((a, b) => b.value - a.value);
}

const formatCalculationNaming = (data) => {
  const colorCodes = ['#022741', '#078BCF', '#099EE1', '#0DC2FF', '#0BB0F3', '#7fd9fd']
  
  const newData = []
  const others = {
    value: 0,
    name: 'Others',
    color: colorCodes[5],
  };

  for (let i = 0; i < data.length; i += 1) {
    const dataElement = data[i]
    if (dataElement.value !== 0) {
      if (i <= 4) {
        newData[i] = {
          value: dataElement.value,
          name: dataElement.name.length > 18 ? `${dataElement.name.substring(0, 17)}...` : dataElement.name,
          color: colorCodes[i],
        }
      } else {
        others.value += dataElement.value
      }
    }
  }

  if (others.value !== 0) newData.push(others)

  return newData
}

export {
  calculateTotalDamage,
  calculateCostPerClass,
  calculateUACCategory,
  calculateUACDetail,
  formatCalculationNaming,
};