/* eslint-disable max-len */
import { useState } from 'react';
import { Button, Checkbox } from '@nazka/nazka.mapframe.components';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { faSquare, faSquareCheck, faSquareMinus } from '@fortawesome/pro-regular-svg-icons';

import './checkbox-group.styl';

function getCheckboxIcon(options, selectedOptions) {
  if (selectedOptions.length === 0) {
    return faSquare;
  }

  if (selectedOptions.length === options.length) {
    return faSquareCheck;
  }

  return faSquareMinus;
}

function CheckboxGroup({
  className,
  disabled,
  label,
  options,
  selectedOptions,
  onSelectedOptionsChange,
}) {
  const [optionsExpanded, setOptionsExpanded] = useState(false);

  return (
    <div className={`checkbox-group ${className || ''}`}>
      <div className="checkbox-group__header">
        <Checkbox
          text={label}
          checked={selectedOptions.length > 0}
          onInputChange={() => {
            if (selectedOptions.length === options.length) {
              onSelectedOptionsChange([]);
            } else {
              onSelectedOptionsChange(options.map(option => option.value));
            }
          }}
          checkedIcon={getCheckboxIcon(options, selectedOptions)}
          disabled={disabled}
        />
        <Button
          className={`checkbox-group__header__expand${optionsExpanded ? ' checkbox-group__header__expand--expanded' : ''}`}
          layout="ghost"
          icon={faChevronDown}
          onClick={() => setOptionsExpanded(prev => !prev)}
          hover={false}
          disabled={disabled}
        />
      </div>
      {optionsExpanded ? (
        <div className="checkbox-group__options">
          {options.map(({ value, label: subLabel }) => {
            return (
              <Checkbox
                key={value}
                text={subLabel}
                checked={selectedOptions.includes(value)}
                onInputChange={() => {
                  if (selectedOptions.includes(value)) {
                    onSelectedOptionsChange(selectedOptions.filter(option => option !== value));
                  } else {
                    onSelectedOptionsChange([...selectedOptions, value]);
                  }
                }}
                disabled={disabled}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

export default CheckboxGroup;