import { useEffect } from 'react';

import { S3_PMTILES_URL } from '@constants/s3.constants';

function BuildingsLayer({ map, opacity }) {
  useEffect(() => {
    if (map) {
      map.setPaintProperty('buildings', 'fill-opacity', opacity);
    }
  }, [map, opacity]);
  
  useEffect(() => {
    map.addSource('buildings', {
      type: 'vector',
      url: `pmtiles://${S3_PMTILES_URL}/buildings.pmtiles`,
    });
  
    map.addLayer({
      id: 'buildings',
      type: 'fill',
      source: 'buildings',
      'source-layer': 'buildings',
      paint: {
        'fill-color': 'rgba(2, 39, 65, 0.8)',
        'fill-outline-color': '#777',
      },
    }, 'z-index-3');

    return () => {
      if (map && !map._removed) {
        if (map.getLayer('buildings')) {
          map.removeLayer('buildings');
        }
      
        if (map.getSource('buildings')) {
          map.removeSource('buildings');
        }
      }
    };
  }, [map]);

  return null;
}

export default BuildingsLayer;