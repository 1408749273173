const customStyles = {
  menuList: provided => ({
    ...provided,
    maxHeight: '200px',
    overflowY: 'auto',

  }),
  dropdownIndicator: (styles, state) => ({ 
    ...styles, 
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
  }),
};

const comparisonSelectCustomStyles = {
  menuList: provided => ({
    ...provided,
    maxHeight: '85px',
    overflowY: 'auto', 
  }),
  dropdownIndicator: (styles, state) => ({ 
    ...styles, 
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
  }),
};

export {
  customStyles,
  comparisonSelectCustomStyles,
};