import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconList } from '@nazka/nazka.mapframe.components';

import { LEGEND_ITEMS } from './legend.constants';
import { setLegendSwitchSelection } from './legend.redux.action';

import './legend.styl'

function Legend() {
  const switchSelection = useSelector(state => state.legend.legendSelection)
  const currentSelectedDamageLayer = useSelector(state => state.layerSelector.currentSelectedDamageLayer)
  const showBuildingDamages = useSelector(state => state.layerSelector.showBuildingDamages);
  const isCreatingNewDamageLayer = useSelector(state => state.newDamageLayer.isCreatingNewDamageLayer);
  const dispatch = useDispatch()

  const { maxTotalDamage, minTotalDamage } = currentSelectedDamageLayer || {};

  useEffect(() => {
    if (maxTotalDamage === minTotalDamage && switchSelection === 'cost') {
      dispatch(setLegendSwitchSelection('classes'));
    }
  }, [dispatch, maxTotalDamage, minTotalDamage, switchSelection]);

  const handleSwitcherToggle = (selection) => {
    dispatch(setLegendSwitchSelection(selection))
  }

  if (!showBuildingDamages || isCreatingNewDamageLayer) {
    return null;
  }

  return (
    <div className="legend">
      <div className="legend__switch">
        <button
          type="button"
          className={`legend__switch__button${switchSelection === 'classes' ? ' legend__switch__button--active' : ''}`}
          onClick={() => handleSwitcherToggle('classes')}
        >
          Classes
        </button>
        <button
          type="button"
          className={`legend__switch__button${switchSelection === 'cost' ? ' legend__switch__button--active' : ''}`}
          onClick={() => handleSwitcherToggle('cost')}
          disabled={minTotalDamage === maxTotalDamage}
        >
          Cost
        </button>
      </div>
      {switchSelection === 'classes' ? (
        <IconList
          className="legend__classes"
          items={LEGEND_ITEMS}
          css={{
            display: 'flex',
            gap: '5px',
            li: {
              fontSize: '12px',
              '> div:first-of-type': {
                paddingRight: '5px',
              },
            },
          }}
        />
      ) : (
        <div className="legend__gradient">
          <div className="legend__gradient__bar" />
          <div className="legend__gradient__labels">
            <span>
              €
              {Math.round(minTotalDamage)}
            </span>
            <span>
              €
              {Math.round(maxTotalDamage)}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default Legend