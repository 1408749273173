import maplibregl from 'maplibre-gl';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import MaplibreglCompare from '@maplibre/maplibre-gl-compare';
import { useSpecificArea } from '@utils/queries/area.queries';
import { useUserSettings } from '@utils/queries/user.queries';
import { bbox } from '@turf/turf';

import BuildingsLayer from '@App/components/BuildingsLayer/BuildingsLayer';
import FloodsLayer from '@App/components/FloodsLayer/FloodsLayer';
import BuildingDamagesLayer from '@App/components/BuildingDamagesLayer/BuildingDamagesLayer';
import UserDamageArea from '@App/components/UserDamageArea/UserDamageArea';

import 'maplibre-gl/dist/maplibre-gl.css';
import './compare-mode-map.styl';
import '@maplibre/maplibre-gl-compare/dist/maplibre-gl-compare.css';
import { createLayers } from './compare-mode-map.utils';

function CompareModeMap() {
  const mapContainer = useRef();
  const mapContainerA = useRef();
  const mapContainerB = useRef();
  const compareRef = useRef();

  const [firstMap, setFirstMap] = useState(null)
  const [secondMap, setSecondMap] = useState(null)

  const showDamageArea = useSelector(state => state.buildingDamages.showAreaOnCompareMode)
  const firstScenario = useSelector(state => state.layerSelector.currentSelectedDamageLayer)
  const secondScenario = useSelector(state => state.buildingDamages.secondScenario)
  const showData = useSelector(state => state.t1.showData);
  const { data: specificArea } = useSpecificArea(firstScenario.areaId)
  const { data: userSettings } = useUserSettings();

  const {
    activeLandUseFeature,
    isFloodLayerActive,
  } = userSettings || {};

  useEffect(() => {
    const aMap = new maplibregl.Map({
      container: mapContainerA.current,
      style: `https://api.maptiler.com/maps/5c22ba3e-a7a2-4fdd-9cc0-2e9304b1812c/style.json?key=${import.meta.env.VITE_MAPTILER_TOKEN}`,
      center: [14.5057515, 46.0569465],
      zoom: 8,
    });

    const bMap = new maplibregl.Map({
      container: mapContainerB.current,
      style: `https://api.maptiler.com/maps/5c22ba3e-a7a2-4fdd-9cc0-2e9304b1812c/style.json?key=${import.meta.env.VITE_MAPTILER_TOKEN}`,
      center: [14.5057515, 46.0569465],
      zoom: 8,
    });
    aMap.addControl(new maplibregl.NavigationControl(), 'top-right');

    compareRef.current = new MaplibreglCompare(
      bMap,
      aMap,
      mapContainer.current,
      {},
    );

    aMap.on('load', () => {
      setFirstMap(bMap)
      createLayers(bMap)
    });
    bMap.on('load', () => {
      setSecondMap(aMap)
      createLayers(aMap)
    });

    return () => {
      if (aMap && bMap) {
        setFirstMap(null)
        setSecondMap(null)
        aMap.remove()
        bMap.remove()
      }
    }
  }, []);

  useEffect(() => {
    const bounds = bbox(specificArea.feature);
    if (firstMap && secondMap) {
      firstMap.fitBounds(bounds, { padding: 100 })
      secondMap.fitBounds(bounds, { padding: 100 })
    }
  }, [firstMap, secondMap, specificArea.feature])

  return (
    <div className={`compare-mode-map-container ${showData ? 'compare-mode-map-container--active' : ''}`} ref={mapContainer}>
      <div className="map-container" ref={mapContainerA} />
      <div className="map-container" ref={mapContainerB} />
      {firstMap && isFloodLayerActive && <FloodsLayer map={firstMap} scenario={firstScenario.scenario} />}
      {secondMap && secondScenario && isFloodLayerActive
        && (
        <FloodsLayer
          map={secondMap}
          scenario={secondScenario.scenario}
          floodColor="rgba(2, 39, 65, 0.4)"
        />
        )}
      {firstMap && activeLandUseFeature === 'Building data' && (
        <BuildingsLayer map={firstMap} />
      )}
      {secondMap && activeLandUseFeature === 'Building data' && (
        <BuildingsLayer map={secondMap} />
      )}

      {firstMap && firstScenario && showDamageArea && (
        <BuildingDamagesLayer map={firstMap} currentSelectedDamageLayer={firstScenario} />
      )}
      {secondMap && secondScenario && showDamageArea && (
        <BuildingDamagesLayer map={secondMap} currentSelectedDamageLayer={secondScenario} />
      )}

      {firstMap && showDamageArea && (
      <UserDamageArea map={firstMap} areaId={firstScenario.areaId} />
      )}
      {secondMap && showDamageArea && (
      <UserDamageArea map={secondMap} areaId={firstScenario.areaId} />
      )}
    </div>
  );
}

export default CompareModeMap
