import { useEffect } from 'react';

import { S3_PMTILES_URL } from '@constants/s3.constants';

function FloodsLayer({ map, scenario = '10', floodColor }) {
  const color = floodColor || 'rgba(3, 103, 165, 0.3)'

  useEffect(() => {
    map.addSource('floods', {
      type: 'vector',
      url: `pmtiles://${S3_PMTILES_URL}/floods.pmtiles`,
    });
  
    map.addLayer({
      id: 'floods',
      type: 'fill',
      source: 'floods',
      'source-layer': scenario,
      paint: {
        'fill-color': color,
        'fill-outline-color': '#777',
      },
    }, 'z-index-2');

    return () => {
      if (map && !map._removed) {
        if (map.getLayer('floods')) {
          map.removeLayer('floods');
        }
      
        if (map.getSource('floods')) {
          map.removeSource('floods');
        }
      }
    };
  }, [color, map, scenario]);

  return null;
}

export default FloodsLayer;