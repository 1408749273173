import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentSelectedDamageLayer } from '@App/components/LayerSelector/layer-selector.redux.action';
import { formatCurrency } from '@services/utils.service';
import { useUserAreas } from '@utils/queries/user.queries';
import { faArrowDownArrowUp, faPencil, faSquare } from '@fortawesome/pro-solid-svg-icons'

import Select from 'react-select';
import { Button, Icon } from '@nazka/nazka.mapframe.components';
import ReactTooltip from 'react-tooltip';
import { useSpecificArea } from '@utils/queries/area.queries';
import { useFloodDamages } from '@utils/queries/calculation.queries';
import { setComparisonModeStatus, setExitComparisonModeStatus, setSecondScenario } from '../building-damages.redux.actions';
import { comparisonSelectCustomStyles, customStyles } from './general-information.styles';

import './general-information.styl';

const areaFormatter = new Intl.NumberFormat();

function GeneralInformation({
  area,
  totalBuildingCount,
  totalBuildingsFloodedCount,
  totalDamage,
}) {
  const dispatch = useDispatch();

  const [isEditOn, setIsEditOn] = useState(false);

  const firstScenario = useSelector(state => state.layerSelector.currentSelectedDamageLayer);
  const isCompareModeOn = useSelector(state => state.buildingDamages.isComparisonModeOn);
  const secondScenario = useSelector(state => state.buildingDamages.secondScenario);

  const { data: floodDamages } = useFloodDamages();
  const { data: userAreas } = useUserAreas();
  const { data: specificArea } = useSpecificArea(firstScenario.areaId);

  const currentSelectValue = { label: firstScenario.description, value: firstScenario };

  const comparisonOptions = floodDamages
    ?.filter(
      damage => damage.id !== firstScenario.id && damage.areaId === firstScenario.areaId && damage.modelId === firstScenario.modelId,
    )
    .map(damage => ({
      label: damage.description,
      value: damage,
    })) || [];

  const mapFloodDamagesToOptions = (damages) => {
    return damages?.map(damage => ({
      label: <span className="general-information__select__damage-name">{damage.description}</span>,
      value: damage,
    }));
  };

  const mapUserAreasToOptions = () => {
    return userAreas?.map(userarea => ({
      label: <span className="general-information__select__area-name">{userarea.areaName}</span>,
      options: mapFloodDamagesToOptions(
        floodDamages?.filter(damage => damage.areaId === userarea.id),
      ),
    }));
  };

  const options = mapUserAreasToOptions()

  const handleSelect = (selectedOption) => {
    dispatch(setCurrentSelectedDamageLayer(selectedOption.value))
  }

  const handleCompareSelect = (selectedOption) => {
    setIsEditOn(false)
    dispatch(setSecondScenario(selectedOption.value))
  }

  const handleSwitching = () => {
    const placeHolder = firstScenario
    dispatch(setCurrentSelectedDamageLayer(secondScenario))
    dispatch(setSecondScenario(placeHolder))
  }

  return (
    <div className="general-information">
      {isCompareModeOn ? (
        <div>
          <Button
            color="#dc3545"
            css={{
              borderRadius: '4px',
            }}
            onClick={() => {
              dispatch(setComparisonModeStatus((false)))
              dispatch(setSecondScenario(null))
              dispatch(setExitComparisonModeStatus(true))
            }}
          >
            Cancel scenario comparison
          </Button>
          <div className="general-information__comparison__mode">
            <div className="general-information__comparison__mode__scenario1">
              <div className="general-information__comparison__mode__scenario1__head">
                <Icon icon={faSquare} size="24px" color="rgba(3,103,165,0.2)" />
                <span>Scenario 1</span>
              </div>
              <div className="general-information__selection__indicator__text">
                <span>{specificArea.areaName}</span>
                <span>{firstScenario.description}</span>
              </div>
            </div>
            {secondScenario && (
              <Button
                icon={faArrowDownArrowUp}
                iconSize="40px"
                color="white"
                hover={false}
                iconProps={{ size: '20px' }}
                css={{
                  position: 'absolute',
                  top: '51%',
                  left: '6px',
                  padding: '4px',
                }}
                onClick={handleSwitching}
              />
            )}

            <hr style={{
              opacity: 0.1,
            }}
            />
            <div className="general-information__comparison__mode__scenario2">
              <div className="general-information__comparison__mode__scenario2__head">
                <Icon icon={faSquare} size="24px" color="rgba(2,39,65,0.6)" />
                {(isEditOn || secondScenario === null) ? (
                  <Select
                    name="calculatedDamages"
                    options={comparisonOptions}
                    className="general-information__comparison__select"
                    onChange={handleCompareSelect}
                    styles={comparisonSelectCustomStyles}
                  />
                ) : (
                  <div className="general-information__comparison__mode__scenario2__head__edit">
                    <span>Scenario 2</span>
                    <Button
                      icon={faPencil}
                      size="20px"
                      color="white"
                      hover={false}
                      onClick={() => setIsEditOn(true)}
                    />
                  </div>
                )}
              </div>
              <div className="general-information__selection__indicator__text">
                <span>{specificArea.areaName}</span>
                <span>{secondScenario ? secondScenario.description : 'Select second scenario'}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="general-informatio__cta__wrapper">
            <Select
              name="calculatedDamages"
              options={options}
              className="general-information__select"
              value={currentSelectValue}
              onChange={handleSelect}
              styles={customStyles}
            />
            <div data-tip data-for="general-informatio__compare-tooltip">
              <Button
                color="#0367A5"
                css={{
                  borderRadius: '4px',
                }}
                onClick={() => {
                  dispatch(setComparisonModeStatus((true)));
                  dispatch(setExitComparisonModeStatus(false));
                }}
                disabled={comparisonOptions.length < 1}
              >
                Compare scenario
              </Button>
            </div>
            {comparisonOptions.length < 1
              ? (
                <ReactTooltip
                  class="general-informatio__compare-tooltip"
                  id="general-informatio__compare-tooltip"
                  type="warning"
                  backgroundColor="#0367A5"
                  arrowColor="rgba(0,0,0,0)"
                  place="right"
                  effect="solid"
                >
                  Comparisons are only possible between two damage
                  layers that are in the same area and have the same
                  land use feature source
                </ReactTooltip>
              ) : null}
          </div>
          <div className="general-information__area-info">
            {/* <h3>Area of interest</h3> */}
            <p>
              <span>Area:</span>
              {` ${areaFormatter.format(area)}m²`}
            </p>
            <p>
              <span>
                N° of
                {firstScenario.modelId === 'uac' ? ' features ' : ' buildings '}
                :
              </span>
              {` ${totalBuildingCount}`}
            </p>
            <p>
              <span>
                N° of
                {firstScenario.modelId === 'uac' ? ' features ' : ' buildings '}
                flooded :
              </span>
              {` ${totalBuildingsFloodedCount}`}
            </p>
            <p>
              <span>Total damage:</span>
              {` ${formatCurrency(totalDamage)}`}
            </p>
          </div>
        </div>
      )}

    </div>
  );
}

export default GeneralInformation;