import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Cell,
  Legend,
  Pie,
  PieChart,
  Sector,
  Tooltip,
} from 'recharts';

import { formatCurrency, fromatTotalDamageValue } from '@services/utils.service';

import './cost-graph.styl';
import { formatCalculationNaming } from '../building-damages.utils';

function renderActiveShape(props) {
  const {
    cx,
    cy,
    endAngle,
    fill,
    innerRadius,
    outerRadius,
    startAngle,
  } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 2}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
}

function renderTooltip(props) {
  const { payload, active } = props;

  if (!active) {
    return null;
  }

  const { name, value } = payload[0];

  return (
    <div className="cost-graph__tooltip">
      {`${name}: ${formatCurrency(value)}`}
    </div>
  );
}

function CostGraph({ data, totalDamage, width }) {
  const [activeIndex, setActiveIndex] = useState(undefined);
  const totalDamageValue = formatCurrency(totalDamage)
  const currentSelectedDamageLayer = useSelector(state => state.layerSelector.currentSelectedDamageLayer)

  const newFilteredData = formatCalculationNaming(data)
  return (
    <div className="cost-graph">
      {newFilteredData.length === 0 ? (
        <span className="cost-graph__no__flood__text">
          No flooded
          {currentSelectedDamageLayer.modelId === 'uac' ? ' features ' : ' buildings '}
          {' '}
          detected
        </span>
      ) : (
        <div>
          {/* <h3>
            Building damages
          </h3> */}
          <PieChart width={width || 400} height={200}>
            <text x={288} y={100} textAnchor="middle" dominantBaseline="middle" style={{ fontWeight: 'bold' }}>
              {fromatTotalDamageValue(totalDamageValue)}
            </text>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={newFilteredData}
              cx="50%"
              cy="50%"
              innerRadius={70}
              outerRadius={90}
              dataKey="value"
              onMouseEnter={(_, index) => setActiveIndex(index)}
              onMouseLeave={() => setActiveIndex(undefined)}
            >
              {newFilteredData.map(({ color }) => <Cell fill={color} />)}
            </Pie>
            <Legend verticalAlign="middle" align="left" layout="vertical" />
            <Tooltip content={renderTooltip} />
          </PieChart>
        </div>
      ) }
      
    </div>
  );
}

export default CostGraph;