import { useSelector } from 'react-redux';
import React, {
  useState, useRef,
  useCallback, 
} from 'react';

import BuildingDamages from '@App/components/BuildingDamages/BuildingDamages';
import { Button } from '@nazka/nazka.mapframe.components';
import { faCircleChevronDown, faCircleChevronUp } from '@fortawesome/pro-light-svg-icons';

import './datapanel.styl';

function Datapanel() {
  const showData = useSelector(state => state.t1.showData);
  const isCompareModeOn = useSelector(state => state.buildingDamages.isComparisonModeOn)
  const secondScenario = useSelector(state => state.buildingDamages.secondScenario)
  const currentSelectedDamageLayer = useSelector(state => state.layerSelector.currentSelectedDamageLayer)

  const [showScrollIndicator, setShowScrollIndicator] = useState(true)
  const datapanelScrollContainer = useRef()

  const onScroll = useCallback((event) => {
    if (event.target.scrollTop > 45) {
      setShowScrollIndicator(false);
    } else if (event.target.scrollTop < 45) {
      setShowScrollIndicator(true);
    }
  }, []);

  const clickHandler = () => {
    if (showScrollIndicator) {
      datapanelScrollContainer.current.scrollTop = 3000
    } else {
      datapanelScrollContainer.current.scrollTop = 0
    }
  }

  return (
    <div className="datapanel">
      <div className="datapanel__scroll" onScroll={onScroll} ref={datapanelScrollContainer}>
        {showData && (
          <BuildingDamages />
        )}
        {(isCompareModeOn && secondScenario) || (currentSelectedDamageLayer?.modelId === 'uac'
          && currentSelectedDamageLayer?.features.length > 0) ? (
            <div className="datapanel__scroll__indicator">
              <Button
                icon={showScrollIndicator ? faCircleChevronDown : faCircleChevronUp}
                className=""
                color="white"
                onClick={clickHandler}
                css={{
                  padding: 0,
                  borderRadius: '50%',
                }}
                iconProps={{
                  size: '28px',
                }}
                hover={false}
              />
            </div>
          ) : (null)}
      </div>
    </div>
  )
}

export default Datapanel;