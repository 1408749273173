function getAvailableClasses(classesByCategory, availableUACClasses) {
  if (!availableUACClasses) {
    return classesByCategory;
  }

  return Object.keys(classesByCategory).reduce((acc, curr) => {
    const classesForCategory = classesByCategory[curr];
    const availableClasses = classesForCategory.filter(({ value }) => availableUACClasses.includes(value));

    if (availableClasses.length) {
      acc[curr] = availableClasses;
    }

    return acc;
  }, {});
}

export {
  getAvailableClasses,
};