import React from 'react';

import NewDamageLayer from '@App/components/NewDamageLayer/NewDamageLayer';
import ErrorBoundary from '@utils/ErrorBoundary/ErrorBoundary';

import './side-panel.styl';

function SidePanel() {
  return (
    <div className="side-panel">
      <ErrorBoundary>
        <NewDamageLayer />
      </ErrorBoundary>
    </div>
  );
}

export default SidePanel;